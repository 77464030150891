import { useTranslation, withTranslation, Trans } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import {
  Button, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import './Header.css';
import Config from '../../config.json';

const Header = (props) => {
  const { t, i18n } = useTranslation('translations');
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState(<div>{t('header.lang.' + i18n.language)}</div>);
  const toggle = () => setIsOpen(!isOpen);

  const changeLanguage = (lang) => {
    setLang(<div>{t('header.lang.' + lang)}</div>);
    i18n.changeLanguage(lang);
  }
  return (
    <header id="my-header">
      <Navbar fixed="top" light expand="md" className={"navbar-inverse my-nav"} >
        <NavbarBrand href="/" className="my-navbar-brand"><img src={'../../assets/images/logo.png'} width="111" height="50" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/about-us"
                active={window.location.pathname === "/about-us"}>{t('header.about-us-tab')}</NavLink>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav activeclassname="navbar__link--active" className={"navbar__link " + (window.location.pathname === "/our-services/consulting" || window.location.pathname === "/our-services/solution-integration" ? "active-link" : "")}>
                  {t('header.our-services-tab')}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/our-services/consulting">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-services/consulting" active={window.location.pathname === "/our-services/consulting"}>{t('header.consulting-tab')}</NavLink>
                  </DropdownItem>
                  <DropdownItem href="/our-services/solution-integration">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-services/solution-integration" active={window.location.pathname === "/our-services/solution-integration"}>{t('header.solution-integration-tab')}</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav activeclassname="navbar__link--active" className={"navbar__link " + (window.location.pathname === "/our-solutions/online-payment" || window.location.pathname === "/our-solutions/offline-payment" || window.location.pathname === "/our-solutions/wechat-mini-program" ? "active-link" : "")}>
                  {t('header.our-solutions-tab')}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem href="/our-solutions/online-payment">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-solutions/online-payment" active={window.location.pathname === "/our-solutions/online-payment"}>{t('header.online-payment-tab')}</NavLink>
                  </DropdownItem>
                  <DropdownItem href="/our-solutions/offline-payment" >
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-solutions/offline-payment" active={window.location.pathname === "/our-solutions/offline-payment"}>{t('header.offline-payment-tab')}</NavLink>
                  </DropdownItem>
                  <DropdownItem href="/our-solutions/wechat-mini-program">
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/our-solutions/wechat-mini-program" active={window.location.pathname === "/our-solutions/wechat-mini-program"}>{t('header.wechat-mini-program-tab')}</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/portfolio" active={window.location.pathname === "/portfolio"}>{t('header.portfolio-tab')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/business-partnership" active={window.location.pathname === "/business-partnership"}>{t('header.business-partnership-tab')}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink activeclassname="navbar__link--active" className="navbar__link" href="/contact-us" active={window.location.pathname === "/contact-us"}>{t('header.contact-us-tab')}</NavLink>
            </NavItem>
            {
              /*
              <NavItem>
                <NavLink activeclassname="navbar__link--active" className="navbar__link" href={Config.LABPAY_MERCHANT_PORTAL_URL} target="_blank">{t('header.merchant-login-tab')}</NavLink>
              </NavItem>
              */
            }
            <NavItem>
              <UncontrolledDropdown nav inNavbar direction="down">
                <DropdownToggle nav caret activeclassname="navbar__link--active" className="navbar__link" >
                  {t('header.language-tab')}
                </DropdownToggle>
                <DropdownMenu right id="language-dropdown-menu">
                  <DropdownItem onClick={() => { changeLanguage('en-US'); }}>
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('en-US'); }}>{t('header.lang.en-US')}</NavLink>
                  </DropdownItem>
                  <DropdownItem onClick={() => { changeLanguage('zh-CN'); }}>
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('zh-CN'); }}>{t('header.lang.zh-CN')}</NavLink>
                  </DropdownItem>
                  <DropdownItem onClick={() => { changeLanguage('zh-HK'); }}>
                    <NavLink activeclassname="navbar__link--active" className="navbar__link" href="#" onClick={() => { changeLanguage('zh-HK'); }}>{t('header.lang.zh-HK')}</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );

}


export default Header;
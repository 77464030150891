import React, { Component, Suspense } from 'react';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Banner from './components/banner/Banner';
import Home from './containers/home/Home';
import AboutUs from './containers/aboutUs/AboutUs';
import Consulting from './containers/consulting/Consulting';
import SolutionIntegration from './containers/solutionIntegration/SolutionIntegration';
import OnlinePayment from './containers/onlinePayment/OnlinePayment';
import OfflinePayment from './containers/offlinePayment/OfflinePayment';
import WechatMiniprogram from './containers/wechatMiniprogram/WechatMiniprogram';
import Portfolio from './containers/portfolio/Portfolio';
import BusinessPartnership from './containers/businessPartnership/BusinessPartnership';
import ContactUs from './containers/contactUs/ContactUs';
import PageNotFound from './containers/pageNotFound/PageNotFound';
import PrivacyPolicy from './containers/privacyPolicy/PrivacyPolicy';
import TermsOfUse from './containers/termsOfUse/TermsOfUse';
import Disclaimer from './containers/disclaimer/Disclaimer';
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Supports from './containers/merchantApp/supports/Supports';
import MerchantAppPrivacyPolicy from './containers/merchantApp/merchantAppPrivacyPolicy/MerchantAppPrivacyPolicy';
import MerchantAppTermsAndConditions from './containers/merchantApp/merchantAppTermsAndConditions/MerchantAppTermsAndConditions';
import { useTranslation } from 'react-i18next';
import MerchantAppFaq from './containers/merchantApp/merchantAppFaq/MerchantAppFaq';
import MerchantAppDisClaimer from './containers/merchantApp/merchantAppDisclaimer/merchantAppDisclaimer';

const Router = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <BrowserRouter>
            <div className={"font-face-" + i18n.language}>
                <Header />
                <div style={{ marginTop: '60px', minHeight: '630px' }}>
                    <Banner />
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/about-us" component={AboutUs} />
                        <Route path="/our-services/consulting" component={Consulting} />
                        <Route path="/our-services/solution-integration" component={SolutionIntegration} />
                        <Route path="/our-solutions/online-payment" component={OnlinePayment} />
                        <Route path="/our-solutions/offline-payment" component={OfflinePayment} />
                        <Route path="/our-solutions/wechat-mini-program" component={WechatMiniprogram} />
                        <Route path="/contact-us" component={ContactUs} />
                        <Route path="/portfolio" component={Portfolio} />
                        <Route path="/business-partnership" component={BusinessPartnership} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/terms-of-use" component={TermsOfUse} />
                        <Route path="/disclaimer" component={Disclaimer} />
                        <Route path="/supports" component={Supports} />
                        <Route path="/merchant-app/privacy-policy" component={MerchantAppPrivacyPolicy} />
                        <Route path="/merchant-app/terms-and-conditions" component={MerchantAppTermsAndConditions} />
                        <Route path="/merchant-app/faq" component={MerchantAppFaq} />
                        <Route path="/merchant-app/disclaimer" component={MerchantAppDisClaimer} />
                        <Route component={PageNotFound} />
                    </Switch>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default Router;

import React, { Component } from 'react';
import { useTranslation   } from 'react-i18next';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './BusinessPartnership.css';

const BusinessPartnership = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      {/* Advantage section */}
      <div className="bg-white-container">
      <Container className="clear-bottom clear-top home-container advantage-container">
        <Row>
          <Col className="section-header-container"><p className="section-header">{t('business-partnership.partner.header')}</p></Col>
        </Row>
        <Row>
          <Col sm={{size:4, order:1}}>
            <div className="box-container">
              <div className="box-img-container">
                <img class="wechatpay box-img" alta="wechatpay" src="../../assets/images/wechatpay.png"></img>
              </div>
              <div className="box-content-container">
                <p className="section-content box-content">{t('business-partnership.partner.wechatpay')}</p>
              </div>
            </div>
          </Col>
          <Col sm={{size:4, order:1}}>
            <div className="box-container">
              <div className="box-img-container">
                <img class="alipay box-img" alta="alipay" src="../../assets/images/alipay.png"></img>
              </div>
              <div className="box-content-container">
                <p className="section-content box-content">{t('business-partnership.partner.alipay')}</p>
              </div>
            </div>
          </Col>
          <Col sm={{size:4, order:1}}>
            <div className="box-container">
              <div className="box-img-container">
                <img class="global-payments box-img" alta="global-payments" src="../../assets/images/global-payments.png"></img>
              </div>
              <div className="box-content-container">
                <p className="section-content box-content">{t('business-partnership.partner.global-payments')}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );

}

export default BusinessPartnership;
import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation, Trans  } from 'react-i18next';
import './Disclaimer.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const Disclaimer = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    /* hide banner */
    document.getElementById("banner-bg").style.display ="none";
  });
  return (
    <div>
        <Container className="clear-bottom clear-top disclaimer-container">
            {/* Disclaimer */}
            <Row><Col><p className="section-content section-content-title"><b>{t('disclaimer.title')}</b></p></Col></Row>
            <Row><Col><p className="section-content">{t('disclaimer.contents.content-1')}</p></Col></Row>
            <Row><Col><p className="section-content">{t('disclaimer.contents.content-2')}</p></Col></Row>
        </Container>
    </div>
  );
}

export default Disclaimer;
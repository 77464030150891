import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './OfflinePayment.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const OfflinePayment = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="bg-white-container">
        <Container className="clear-bottom clear-top offline-payment-container">
          <Row>
            <Col>
              <div class="offline-payment-container">
                <p className="section-content">{t('offline-payment.contents.content-1')}</p>
              </div>
            </Col>
          </Row>
        </Container>
        {/* POS feature section */}
        <div className="bg-white-container"> 
          <Container className="clear-bottom clear-top home-container pos-feature-container">
            <Row>
              <Col>
                <div className="left-right-container">
                  <div class="pos-feature-img-container left-container">
                    <img class="pos-feature-1 href-img" alta="pos-feature-1" src="../../assets/images/pos-feature_1.png"></img>
                  </div>
                  <div class="service-content-container right-container">
                    <p className="section-content pos-feature-content">{t('offline-payment.pos-feature.feature-1')}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="left-right-container">
                  <div class="pos-feature-img-container right-container">
                    <img class="pos-feature-2 href-img" alta="pos-feature-2" src="../../assets/images/pos-feature_2.png"></img>
                  </div>
                  <div class="service-content-container left-container">
                    <p className="section-content pos-feature-content">{t('offline-payment.pos-feature.feature-2')}</p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="left-right-container">
                  <div class="pos-feature-img-container left-container">
                    <img class="pos-feature-3 href-img" alta="pos-feature-3" src="../../assets/images/pos-feature_3.png"></img>
                  </div>
                  <div class="service-content-container right-container">
                    <p className="section-content pos-feature-content">{t('offline-payment.pos-feature.feature-3')}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default OfflinePayment;
import React, { Component } from 'react';
import { useTranslation   } from 'react-i18next';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import './AboutUs.css';

const AboutUs = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      {/* Story section */}
      <div className="bg-white-container">
        <Container className="clear-bottom clear-top service-container">
          <Row>
            <Col>
              <div class="our-story-container">
                <p className="section-content">{t('about-us.objective.contents.content-1')}</p>
                <p className="section-content">{t('about-us.objective.contents.content-2')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );

}

export default AboutUs;
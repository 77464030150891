import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './WechatMiniprogram.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const WechatMiniprogram = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
    <div className="bg-white-container">
      <Container className="clear-bottom clear-top wechat-mini-program-container">
        <Row>
          <Col>
            <div class="wechat-mini-program-container">
              <p className="section-content">{t('wechat-mini-program.contents.content-1')}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
}

export default WechatMiniprogram;
import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import Sitemap from '../sitemap/Sitemap';
import { Container, Row, Col } from 'reactstrap';
import './Footer.css';

const Footer = (props) => {
  const { t, i18n } = useTranslation("translations");
  return (
    <footer id="my-footer" className="base-bg-color-grey">
      <Sitemap />
      <Container>
        <Row>
          <Col md={{ size: '6' }} className="footer-text copy-right-text">{t('footer.copy-right-text')}</Col>
          <Col md={{ size: '6'}}>
            <Row className="text-center" id="footer-link-container">
              <a className="footer-text footer-link" href="/privacy-policy" >{t('footer.privacy-policy-tab')}</a>
              <hr className="footer-hr"/>
              <a className="footer-text footer-link" href="/terms-of-use">{t('footer.terms-of-use-tab')}</a>
              <hr className="footer-hr"/>
              <a className="footer-text footer-link" href="/disclaimer">{t('footer.disclaimer-tab')}</a>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Portfolio.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const Portfolio = (props) => {
  const { t, i18n } = useTranslation();
  function importAll(r) {
    return r.keys().map(r);
  }
  const images = importAll(require.context('../../assets/images/merchant-logo/', false, /\.(png|jpe?g|svg)$/));

  return (
    <div>
      {/* Cooperative merchant section */}
      <div className="bg-white-container">
      <Container className="clear-bottom clear-top home-container cooperative-merchant-container">
        <Row>
          <Col className="section-header-container"><p className="section-header">{t('portfolio.cooperative-merchant.header')}</p></Col>
        </Row>
        <Row>
          <Col>
            <div className="section-body" id="merchants-logo-container">
              {
                images.map( image => {
                  return <img class="merchants-logo" alta="merchants" src={image}></img>
                })
              }
              <p className="section-content">{t('portfolio.cooperative-merchant.more')}</p>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
}

export default Portfolio;
import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Home.css';
import { Container, Row, Col, Button, Input, Modal, Glyphicon  } from 'reactstrap';
import { Link } from "react-router-dom";
import Config from '../../config.json';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBCard, MDBCardBody } from 'mdbreact';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const sendNewsLetterEmail = useCallback(async(formValues) =>{
    // update state
    setIsSending(true)

    var template_params = {
      "email": formValues["email"],
   }
   
   var service_id = "zoho";
   var template_id = "service-enquiry";
   emailjs.init('user_9pK5foMcgX6LMAlknObiF');
   emailjs.send(service_id, template_id, template_params)
          .then((response) => {
            setIsSending(false)
            alert("SUCCESS!");
          }, (err) => {
            setIsSending(false)
            alert("ERROR!");
          });

  }, [isSending]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('home.stay-connected-form.error.email.invalid').required('home.stay-connected-form.error.email.required'),
  })
  const {handleSubmit, handleBlur, handleChange, values, errors, touched} = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: formValues => {
      sendNewsLetterEmail(formValues);
    }
  });

  return (
    <div>
      <Container className="clear-bottom clear-top stay-connected-container">
        <Row>
          <Col>
              <p className="section-content stay-connected-statement">{t('home.stay-connected.title')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="section-content">{t('home.stay-connected-form.subscription-statement')}</p>
          </Col>
        </Row>
        <Row>
          <Col >
            <form onSubmit={handleSubmit}>
              <Row>
                <Col sm={{ size: 4, order: 2 }}>
                  <input
                  type="email"
                  id="stayConnectedFormEmail"
                  className="form-control"
                  onChange={handleChange('email')}
                  onBlur={handleBlur}
                  value={values.email}
                />                    
                <p className="home-stay-connected-form-error">{errors.email? t(errors.email) : null}</p>
                </Col>
                <Col sm={{ size: 4, order: 2 }}>
                <div className="text-center stay-connected-btn-container">
                  <button mdbBtn outline color="#" className="my-btn home-newsletter-btn" type="submit" disabled={isSending}>
                  {t('home.stay-connected-form.label.submit-button')} <MDBIcon far icon="paper-plane" className="ml-1" />
                  </button>
                </div>
                </Col>
              </Row>

            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
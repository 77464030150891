import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Consulting.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const Consulting = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="bg-white-container">
        <Container className="clear-bottom clear-top consulting-container">
          <Row>
            <Col>
              <div class="consulting-container">
                <p className="section-content">{t('consulting.contents.content-1')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Consulting;
import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation   } from 'react-i18next';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import Amap from '../../components/amap/Amap';
import './ContactUs.css';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBCard, MDBCardBody } from 'mdbreact';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from 'emailjs-com';

const ContactUs = (props) => {
  const { t, i18n } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const sendEquiryEmail = useCallback(async(formValues) =>{
    // update state
    setIsSending(true)

    var template_params = {
      "name": formValues["name"],
      "email": formValues["email"],
      "companyName": formValues["companyName"],
      "subject": formValues["subject"],
      "message": formValues["message"]
   }
   
   var service_id = "zoho";
   var template_id = "contactus";
   emailjs.init('user_9pK5foMcgX6LMAlknObiF');
   emailjs.send(service_id, template_id, template_params)
          .then((response) => {
            setIsSending(false)
            alert("SUCCESS!");
          }, (err) => {
            setIsSending(false)
            alert("ERROR!");
          });

  }, [isSending]);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('contact-us.enquiry-form.error.name.required'),
    email: Yup.string().email('contact-us.enquiry-form.error.email.invalid').required('contact-us.enquiry-form.error.email.required'),
    companyName: Yup.string().required('contact-us.enquiry-form.error.company-name.required'),
    subject: Yup.string().required('contact-us.enquiry-form.error.subject.required'),
    message: Yup.string().required('contact-us.enquiry-form.error.message.required')
  })
  const {handleSubmit, handleBlur, handleChange, values, errors, touched} = useFormik({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
      subject: '',
      message: ''
    },
    validationSchema,
    onSubmit: formValues => {
      sendEquiryEmail(formValues);
    }
  });

  return (
    <div className="contact-us-body">
      <Container className="clear-bottom clear-top contact-us-form-container">
        <Row>
          <Col className="section-header-container"><p className="section-header">{t('contact-us.enquiry-form.header')}</p></Col>
        </Row>
        <Row className="clear-bottom">
         <Col>
            <MDBContainer className="write-to-us-container">
              <MDBRow>
                <MDBCol md="12">
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="contactUsFormName" className="grey-text">
                    {t('contact-us.enquiry-form.label.name')}
                    </label>
                    <input
                      type="text"
                      id="contactUsFormName"
                      className="form-control"
                      onChange={handleChange('name')}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <p className="contact-us-form-error">{errors.name? t(errors.name) : null}</p>
                    <label htmlFor="contactUsFormEmail" className="grey-text">
                    {t('contact-us.enquiry-form.label.email')}
                    </label>
                    <input
                      type="email"
                      id="contactUsFormEmail"
                      className="form-control"
                      onChange={handleChange('email')}
                      onBlur={handleBlur}
                      value={values.email}
                    />                    
                    <p className="contact-us-form-error">{errors.email? t(errors.email) : null}</p>
                    <label
                      htmlFor="contactUsFormCompanyName"
                      className="grey-text"
                    >
                    {t('contact-us.enquiry-form.label.company-name')}
                    </label>
                    <input
                      type="text"
                      id="contactUsFormCompanyName"
                      className="form-control"
                      onChange={handleChange('companyName')}
                      onBlur={handleBlur}
                      value={values.companyName}
                    />
                    <p className="contact-us-form-error">{errors.companyName? t(errors.companyName) : null}</p>
                    <label
                      htmlFor="contactUsFormSubject"
                      className="grey-text"
                    >
                    {t('contact-us.enquiry-form.label.subject')}
                    </label>
                    <input
                      type="text"
                      id="contactUsFormSubject"
                      className="form-control"
                      onChange={handleChange('subject')}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                    <p className="contact-us-form-error">{errors.subject? t(errors.subject) : null}</p>
                    <label
                      htmlFor="contactUsFormMessage"
                      className="grey-text"
                    >
                    {t('contact-us.enquiry-form.label.message')}
                    </label>
                    <textarea
                      type="text"
                      id="contactUsFormMessage"
                      className="form-control"
                      onChange={handleChange('message')}
                      onBlur={handleBlur}
                      value={values.message}
                      rows="3"
                    />
                    <p className="contact-us-form-error">{errors.message? t(errors.message) : null}</p>
                    <div className="text-center">
                      <button mdbBtn outline color="#" className="my-btn contact-us-btn" type="submit" disabled={isSending}>
                      {t('contact-us.enquiry-form.label.submit-button')} <MDBIcon far icon="paper-plane" className="ml-1" />
                      </button>
                    </div>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </Col>
        </Row>
      </Container>
      <Container className="clear-bottom clear-top contact-us-info-container">        
        <Row>
          <Col className="section-header-container"><p className="section-header">{t('contact-us.contact-info.header')}</p></Col>
        </Row>
        <Row>
          <Col>
            <MDBContainer className="contact-info-container">
              <Row>
                <Col sm={{size:6}}>
                  <p className="section-content"><MDBIcon icon="phone" className="contact-us-icon"/><a href={"tel:" + t('contact-us.contact-info.support-hotline.content')}>{t('contact-us.contact-info.support-hotline.content')}</a>
                  </p>
                  <p className="section-content"><MDBIcon icon="mail-bulk" className="contact-us-icon"/><a href = {"mailto: " + t('contact-us.contact-info.email.content')}>{t('contact-us.contact-info.email.content')}</a></p>
                <p className="section-content"><MDBIcon far icon="address-book" className="contact-us-icon"/>{t('contact-us.contact-info.address.content')} </p>              
                </Col>
                <Col sm={{size:6}}>
                  <Amap className="contact-info-map" pos={{ longitude : t('contact-us.contact-info.address.longitude'), latitude: t('contact-us.contact-info.address.latitude') }}/>
                </Col>
              </Row>
            </MDBContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ContactUs;
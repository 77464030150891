import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './SolutionIntegration.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const SolutionIntegration = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="bg-white-container">
        <Container className="clear-bottom clear-top solution-integration-container">
          <Row>
            <Col>
              <div class="solution-integration-container">
                <p className="section-content">{t('solution-integration.contents.content-1')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default SolutionIntegration;
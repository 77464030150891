import React, { Component, useState, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './TermsOfUse.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const TermsOfUse = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    /* hide banner */
    document.getElementById("banner-bg").style.display = "none";
  });
  return (
    <div>
      <Container className="clear-bottom clear-top terms-of-use-container">
        {/* Acceptance of Terms */}
        <Row><Col><p className="section-content section-content-title"><b>{t('terms-of-use.acceptance-of-terms.title')}</b></p></Col></Row>
        <Row>
          <Col>
            <p className="section-content">
              <Trans i18nKey="terms-of-use.acceptance-of-terms.contents.content-1">
                This section sets out the terms and conditions (“Terms”) of your access to <a href={t('terms-of-use.acceptance-of-terms.contents.content-1-website')} >{{ website: t('terms-of-use.acceptance-of-terms.contents.content-1-website') }}</a> (the “Site”).  By accessing the Site, you are agreeing to these Terms.  This Site is primarily intended for access from within Hong Kong, and we cannot guarantee that the Site or the information thereon complies with laws or regulations of other countries.
                    </Trans>
            </p>
          </Col>
        </Row>
        <Row><Col><p className="section-content">{t('terms-of-use.acceptance-of-terms.contents.content-2')}</p></Col></Row>
      </Container>
    </div>
  );
}

export default TermsOfUse;
import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './Supports.css';
import { Container, Row, Col, Button, Input, Modal, div } from 'reactstrap';

const Supports = (props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    /* hide header , footer, banner */
    document.getElementById("my-header").style.display ="none";
    document.getElementById("my-footer").style.display ="none";
    document.getElementById("banner-bg").style.display ="none";
  });
  return (
    <div>
        <div class="container">
            <div class="row">
                <div class= "col-md-9 mx-auto">
                <div class="col-md-12" style={{fontWeight: '500', marginBottom: '40px'}}>
                    <h4>Contents</h4>
                    <p><a href="/supports#payment-terminal">1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Payment Terminal</span></a></p>
                    <p><a href="/supports#general-introduction">&nbsp;&nbsp;&nbsp;&nbsp;1.1&nbsp;&nbsp;&nbsp;&nbsp;<span>General Introduction</span></a></p>
                    <p><a href="/supports#cashier">&nbsp;&nbsp;&nbsp;&nbsp;1.2&nbsp;&nbsp;&nbsp;&nbsp;<span>Cashier</span></a></p>
                    <p><a href="/supports#transaction-history">&nbsp;&nbsp;&nbsp;&nbsp;1.3&nbsp;&nbsp;&nbsp;&nbsp;<span>Transaction History</span></a></p>
                    <p><a href="/supports#refund">&nbsp;&nbsp;&nbsp;&nbsp;1.4&nbsp;&nbsp;&nbsp;&nbsp;<span>Refund</span></a></p>
                    <p><a href="/supports#refund-history">&nbsp;&nbsp;&nbsp;&nbsp;1.5&nbsp;&nbsp;&nbsp;&nbsp;<span>Refund History</span></a></p>
                    <p><a href="/supports#day-end-settlement">&nbsp;&nbsp;&nbsp;&nbsp;1.6&nbsp;&nbsp;&nbsp;&nbsp;<span>Day End Settlement</span></a></p>
                    <p><a href="/supports#day-end-settlement-history">&nbsp;&nbsp;&nbsp;&nbsp;1.7&nbsp;&nbsp;&nbsp;&nbsp;<span>Day End Settlement History</span></a></p>
                    <p><a href="/supports#merchant-settings">&nbsp;&nbsp;&nbsp;&nbsp;1.8&nbsp;&nbsp;&nbsp;&nbsp;<span>Merchant Settings</span></a></p>
                    <p><a href="/supports#how-to-use-wechat-wallet">&nbsp;&nbsp;&nbsp;&nbsp;1.9&nbsp;&nbsp;&nbsp;&nbsp;<span>How to use WeChat Wallet</span></a></p>
                    <p><a href="/supports#how-to-use-alipay">&nbsp;&nbsp;&nbsp;&nbsp;1.10&nbsp;&nbsp;&nbsp;&nbsp;<span>How to use Alipay</span></a></p>
                </div>
                <div class="col-md-12" class="section" id="payment-terminal" >
                    <h4>Payment Terminal</h4>
                </div>
                <div class="col-md-12" class="section" id="general-introduction">
                    <h5>1.1&nbsp;&nbsp;&nbsp;&nbsp;General Introduction</h5>
                    <div><img src= "../../assets/images/pos-menu/pos-menu_1.1.jpg" alt="pos-menu_1.1" /></div>
                    <p>The POS terminal is a digital payment solution integrated with different popular payment methods in the market. </p>
                    <p>Nowadays, many payment methods are actively in use by consumers. In order to allow consumers have better digital payment experience and enhance the payment accuracy and efficiency of the merchant, LAB Pay payment solution is built to facilitate the whole payment process. </p>
                    <p>WeChat Pay and Alipay are two of the largest digital payment platforms and usage in the market and LAB Pay payment solution is fully integrated with these two payment platforms at the moment. LAB Pay payment solution supports both Hong Kong dollar payment and cross-border RMB payment.</p>
                </div>
                <div class="col-md-12" class="section" id="cashier">
                    <h5>1.2&nbsp;&nbsp;&nbsp;&nbsp;Cashier</h5>
                    <p>The Cashier button is the main function of the terminal. The payment procedure is initiated as below: </p>
                    <div className="pos-menu_img" ><img src= "../../assets/images/pos-menu/pos-menu_1.2a.jpg" alt="pos-menu_1.2a" /> <span>img 1.2a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.2b.jpg" alt="pos-menu_1.2b" /> <span>img 1.2b</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.2c.jpg" alt="pos-menu_1.2c" /> <span>img 1.2c</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.2d.jpg" alt="pos-menu_1.2d" /> <span>img 1.2d</span></div>
                    <table id="cashier-table" className="my-table">
                    <thead>
                        <tr>
                        <th id="cashier-table-description" className="my-table-description"><b>Description</b></th>
                        <th id="cashier-table-ref-img" className="my-table-ref-img"><b>Refer to img #</b></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Step 1: Input the amount of the payment</td>
                        <td>img 1.2a</td>
                    </tr>
                    <tr>
                        <td>Step 2: Select the payment method (WeChat Pay / Alipay)</td>
                        <td>img 1.2a</td>
                    </tr>
                    <tr>
                        <td>Step 3: Press enter on screen</td>
                        <td>img 1.2a</td>
                    </tr>
                    <tr>
                        <td>Step 4: Scan the payment code on customer’s mobile</td>
                        <td>img 1.2b, img 1.2c</td>
                    </tr>
                    <tr>
                        <td>Step 5: Check payment status on terminal, and get merchant receipt</td>
                        <td>img 1.2d</td>
                    </tr>
                    <tr>
                        <td>Step 6(optional): Print and re-print customer receipt</td>
                        <td>img 1.2d</td>
                    </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-md-12" class="section" id="transaction-history">
                    <h5>1.3&nbsp;&nbsp;&nbsp;&nbsp;Transaction History</h5>
                    <p>For reviewing the transaction history, merchant could click on the “TXN HISTORY” button to get the list view of all the successful and unsuccessful payment. </p>
                    <p>By clicking on the transaction row, you could get the detail information about the transaction (refer to the img 1.3b). </p>
                    <p>In the Transaction detail view, you could perform i) refund, ii) reprint customer receipt and iii) reprint merchant copy. If the transaction is completely refunded, the refund button would no longer exist on this screen. </p>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.3a.jpg" alt="pos-menu_1.3a" /> <span>img 1.3a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.3b.jpg" alt="pos-menu_1.3b" /> <span>img 1.3b</span></div>
                </div>
                <div class="col-md-12" class="section" id="refund">
                    <h5>1.4&nbsp;&nbsp;&nbsp;&nbsp;Refund</h5>
                    <p>After successful payment, consumer may ask for refund. Merchant could accept fully refund or partially refund with the following assumptions:</p>
                    <p>i)	Refund must be completed within 100 days of the payment</p>
                    <p>ii)	Refund amount must equal to or smaller than the payment amount</p>
                    <p>iii)	Refund would not be processed if the amount of the settlement funds is not sufficient</p>
                    <p>If the transaction is completely refunded, the refund button would no longer exist on the screen. </p>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.4a.jpg" alt="pos-menu_1.4a" /> <span>img 1.4a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.4b.jpg" alt="pos-menu_1.4b" /> <span>img 1.4b</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.4c.jpg" alt="pos-menu_1.4c" /> <span>img 1.4c</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.4d.jpg" alt="pos-menu_1.4d" /> <span>img 1.4d</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.4e.jpg" alt="pos-menu_1.4e" /> <span>img 1.4e</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.4f.jpg" alt="pos-menu_1.4f" /> <span>img 1.4f</span></div>
                    <table className="my-table" id="refund-table">
                        <thead>
                        <tr>
                            <th className="my-table-description"><b>Description</b></th>
                            <th className="my-table-ref-img"><b>Refer to img #</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td>
                            Step 1a: Select the transaction row under transaction history
                        </td>
                        <td>img 1.4a</td>
                        </tr>
                        <tr>
                            <td style={{borderTop: "0px", textAlign: "center"}}>OR</td>
                            <td style={{borderTop: "0px", textAlign: "center"}}>OR</td>
                        </tr>
                        <tr>
                        <td style={{borderTop: "0px"}}>
                            Step 1b: Click the right top corner scan button to scan the QR code on the printed receipt
                        </td>
                        <td style={{borderTop: "0px"}}>img 1.4a, 1.4b</td>
                        </tr>
                        <tr>
                        <td>Step 2: Review the transaction detail and click refund button on the bottom left button</td>
                        <td>img 1.4c</td>
                        </tr>
                        <tr>
                        <td>Step 3: Input the refund amount and click refund button to process, REFUND PASSWORD is required</td>
                        <td>img 1.4d</td>
                        </tr>
                        <tr>
                        <td>Step 4: Check payment status on terminal, and get merchant refund receipt </td>
                        <td>img 1.4e</td>
                        </tr>
                        <tr>
                        <td>Step 5: Refunded amount could review on transaction history page and refund history page</td>
                        <td>img 1.4f, 1.5a</td>
                        </tr>
                        <tr>
                        <td>Step 6(optional): Print and re-print customer refund receipt</td>
                        <td>img 1.4f</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12" class="section" id="refund-history">
                    <h5>1.5&nbsp;&nbsp;&nbsp;&nbsp;Refund History</h5>
                    <p>For reviewing the refund history, merchant could click on the “REFUND HISTORY” button to get the list view of all the successful refund records. </p>
                    <p>By clicking on the refund row, you could get the detail information about the transaction (refer to the img 1.5b). </p>
                    <p>In the Transaction detail view, you could perform i) reprint customer receipt and ii) reprint merchant copy. </p>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.5a.jpg" alt="pos-menu_1.5a" /> <span>img 1.5a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.5b.jpg" alt="pos-menu_1.5b" /> <span>img 1.5b</span></div>   
                </div>
                <div class="col-md-12" class="section" id="day-end-settlement">
                    <h5>1.6&nbsp;&nbsp;&nbsp;&nbsp;Day End Settlement</h5>
                    <p>The Day End Settlement page is located under the FUNCTION page. Merchant could clear the settlement at the end of business day. The settlement procedure is initiated as below: </p>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.6a.jpg" alt="pos-menu_1.6a" /> <span>img 1.6a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.6b.jpg" alt="pos-menu_1.6b" /> <span>img 1.6b</span></div>   
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.6c.jpg" alt="pos-menu_1.6c" /> <span>img 1.6c</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.6d.jpg" alt="pos-menu_1.6d" /> <span>img 1.6d</span></div>   
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.6e.jpg" alt="pos-menu_1.6e" /> <span>img 1.6e</span></div>
                    <table className="my-table" id="refund-table">
                    <thead>
                        <tr>
                        <th className="my-table-description"><b>Description</b></th>
                        <th className="my-table-ref-img"><b>Refer to img #</b></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                        Step 1: Click DAY END SETTLEMENT under the FUNCTION page
                        </td>
                        <td>img 1.6a</td>
                    </tr>
                    <tr>
                        <td >
                        Step 2: The settlement period is set automatically from the last settlement time until the current time. Check start to proceed
                        </td>
                        <td>img 1.6b</td>
                    </tr>
                    <tr>
                        <td>Step 3: Input the manager password for confirmation</td>
                        <td>img 1.6c</td>
                    </tr>
                    <tr>
                        <td>Step 4: The settlement result is listed on the screen. There are summary, WeChat Pay and Alipay sections</td>
                        <td>img 1.6d, 1.6e</td>
                    </tr>
                    <tr>
                        <td>Step 5(optional): Click the print button for printing the summary, WeChat Pay and Alipay sections</td>
                        <td>img 1.6e</td>
                    </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-md-12" class="section" id="day-end-settlement-history">
                    <h5>1.7&nbsp;&nbsp;&nbsp;&nbsp;Day End Settlement History</h5>
                    <p>For reviewing the settlement history, merchant could click on the “SETTLEMENT HISTORY” button (refer to the img 1.7b) to get the list view of all the settlement records. </p>
                    <p>By clicking on the settlement row, you could review the settlement record of specific period (refer to the img 1.7c). </p>
                    <p>In the settlement detail view, you could print the summary, WeChat Pay and Alipay sections of the settlement (refer to the img 1.6e). </p>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.7a.jpg" alt="pos-menu_1.7a" /> <span>img 1.7a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.7b.jpg" alt="pos-menu_1.7b" /> <span>img 1.7b</span></div>   
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.7c.jpg" alt="pos-menu_1.7c" /> <span>img 1.7c</span></div>
                    <table className="my-table" id="refund-table">
                    <thead>
                        <tr>
                        <th className="my-table-description"><b>Description</b></th>
                        <th className="my-table-ref-img"><b>Refer to img #</b></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                        Step 1: Click DAY END SETTLEMENT under the FUNCTION page
                        </td>
                        <td>img 1.7a</td>
                    </tr>
                    <tr>
                        <td >
                        Step 2: Click SETTLEMENT HISTORY
                        </td>
                        <td>img 1.7b</td>
                    </tr>
                    <tr>
                        <td>Step 3: A list of settlement history would be displayed</td>
                        <td>img 1.7c</td>
                    </tr>
                    <tr>
                        <td>Step 4: Select the row to review the settlement record of specific period</td>
                        <td>img 1.7c</td>
                    </tr>
                    <tr>
                        <td>Step 5(optional): Click the print button for printing the summary, WeChat Pay and Alipay sections</td>
                        <td>img 1.6e</td>
                    </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-md-12" class="section" id="merchant-settings">
                    <h5>1.8&nbsp;&nbsp;&nbsp;&nbsp;Merchant Settings</h5>
                    <p>The SETTINGS page is located under the FUNCTION page. Merchant could perform the following actions on the terminal:</p>
                    <p>i)	Language (img 1.8d)</p>
                    <p>ii)	Refund Password (img 1.8e)</p>
                    <p>iii)	Manager Password (img 1.8f)</p>
                    <p>iv)	Merchant Name (img 1.8g)</p>
                    <p>v)	Outlet Name (img 1.8h)</p>
                    <p>vi)	Product Description (img 1.8i)</p>
                    <p>vii)	Test Printer</p>
                    <p>viii)	View Terminal Log (img 1.8j)</p>
                    <p>For changing any setting above, “Manager Password” is required for verifying the identity.  </p>
                    <p>Default Manager Password: 000000</p>
                    <p>Default Refund Password: 123456</p>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8a.jpg" alt="pos-menu_1.8a" /> <span>img 1.8a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8b.jpg" alt="pos-menu_1.8b" /> <span>img 1.8b</span></div>   
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8c.jpg" alt="pos-menu_1.8c" /> <span>img 1.8c</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8d.jpg" alt="pos-menu_1.8d" /> <span>img 1.8d</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8e.jpg" alt="pos-menu_1.8e" /> <span>img 1.8e</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8f.jpg" alt="pos-menu_1.8f" /> <span>img 1.8f</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8g.jpg" alt="pos-menu_1.8g" /> <span>img 1.8g</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8h.jpg" alt="pos-menu_1.8h" /> <span>img 1.8h</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8i.jpg" alt="pos-menu_1.8i" /> <span>img 1.8i</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.8j.jpg" alt="pos-menu_1.8j" /> <span>img 1.8j</span></div>
                </div>
                <div class="col-md-12" class="section" id="how-to-use-wechat-wallet">
                    <h5>1.9&nbsp;&nbsp;&nbsp;&nbsp;How to use WeChat Wallet</h5>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.9a.jpg" alt="pos-menu_1.9a" /> <span>img 1.9a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.9b.jpg" alt="pos-menu_1.9b"/> <span>img 1.9b</span></div>   
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.9c.jpg" alt="pos-menu_1.9c" /> <span>img 1.9c</span></div>
                    <table className="my-table" id="how-to-use-wechat-wallet-table">
                    <thead>
                        <tr>
                        <th className="my-table-description"><b>Description</b></th>
                        <th className="my-table-ref-img"><b>Refer to img #</b></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Step 1: Select Me tab on the bottom navigation bar</td>
                        <td>img 1.9a</td>
                    </tr>
                    <tr>
                        <td>Step 2: Select Quick Pay tab on the left corner</td>
                        <td>img 1.9b</td>
                    </tr>
                    <tr>
                        <td>Step 3: Scan the QR code to process the transaction</td>
                        <td>img 1.9c</td>
                    </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-md-12" class="section" id="how-to-use-alipay">
                    <h5>1.10&nbsp;&nbsp;&nbsp;&nbsp;How to use Alipay</h5>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.10a.jpg" alt="pos-menu_1.10a" /> <span>img 1.10a</span></div>
                    <div className="pos-menu_img"><img src= "../../assets/images/pos-menu/pos-menu_1.10b.jpg" alt="pos-menu_1.10b" /> <span>img 1.10b</span></div>   
                    <table className="my-table" id="how-to-use-alipay-table">
                    <thead>
                        <tr>
                        <th className="my-table-description"><b>Description</b></th>
                        <th className="my-table-ref-img"><b>Refer to img #</b></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Step 1: Select Pay on the top navigation bar</td>
                        <td>img 1.10a</td>
                    </tr>
                    <tr>
                        <td>Step 2: Scan the QR code to process the transaction</td>
                        <td>img 1.10b</td>
                    </tr>
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
    </div>
  );
}

export default Supports;
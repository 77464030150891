import React, { Component, useState, useCallback, useEffect} from 'react';
import { useTranslation   } from 'react-i18next';
import './OnlinePayment.css';
import { Container, Row, Col, Button, Input, Modal } from 'reactstrap';

const OnlinePayment = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="bg-white-container">
        <Container className="clear-bottom clear-top online-payment-container">
          <Row>
            <Col>
              <div class="online-payment-container">
                <p className="section-content">{t('online-payment.contents.content-1')}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OnlinePayment;
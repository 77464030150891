import React, { Component, useState } from 'react';
import { useTranslation, withTranslation  } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import { withRouter} from "react-router-dom";
import './Banner.css';


const Banner = (props) => {
  const { t, i18n } = useTranslation("translations");
  var pageContainerName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) != "" ? window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) :"home" ;
  const bannerUrl = "../../assets/images/banner/"+ pageContainerName + ".png";

  return (
    <div id="banner-bg" className="banner-bg clear-bottom" style={{backgroundImage : "url(" + bannerUrl +")"}}>
        <Container className="banner-slogan-container">
          <Row>
            <Col>
              <p className="banner-slogan">{t('banner-slogan.' + pageContainerName +'.slogan-1')}</p>
              <p className="banner-slogan">{t('banner-slogan.' + pageContainerName +'.slogan-2')}</p>
              <p className="banner-slogan">{t('banner-slogan.' + pageContainerName +'.slogan-3')}</p>
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default Banner;